body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  align-items: center;
  justify-content: center;
}

.data {
  font-weight: bold;
}

.IpInformation {
  background: lightblue;
  border: 2px dashed blue;
  padding: 20px;
}

.ip {
  padding: 5px;
  border: 2px solid red;
  text-align: center;
}
